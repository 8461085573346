import { BigNumber } from '@0x/utils';
import {
    ActionType,
    EnrollmentStatus,
    OneClickTradingStorageData,
    ParamTraderUpdateKind,
    RequestFailure,
    RequestSequenced,
    Trader,
    UpdateOneClickTradingIntent,
    UpdateProfileIntent,
    WithdrawDDXIntent,
} from '@derivadex/types';
import { ONE_CLICK_TRADING_DATA } from '@derivadex/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'typesafe-actions';

export type ProfileState = {
    availDDX: BigNumber;
    lockedDDX: BigNumber;
    estimatedRewards: BigNumber;
    payFeesInDDX: boolean;
    isDefault: boolean; // true when trader isn't found in DB
    isPayFeesInDDXLoading: boolean;
    epochId: BigNumber;
    txOrdinal: BigNumber;
    latestUpdate:
        | {
              amount: BigNumber;
              type: ParamTraderUpdateKind;
          }
        | undefined;
    enrollmentStatus: EnrollmentStatus;
    oneClickTradingEnabled: boolean;
    disableOneClickTradingPrompt: boolean;
    oneClickTradingData: OneClickTradingStorageData | undefined;
};

export const initialProfileState: ProfileState = {
    availDDX: new BigNumber(0),
    lockedDDX: new BigNumber(0),
    payFeesInDDX: false,
    isDefault: true,
    isPayFeesInDDXLoading: false,
    estimatedRewards: new BigNumber(0),
    epochId: new BigNumber(0),
    txOrdinal: new BigNumber(0),
    latestUpdate: undefined,
    enrollmentStatus: EnrollmentStatus.NONE,
    oneClickTradingEnabled: false,
    disableOneClickTradingPrompt: false,
    oneClickTradingData: undefined,
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialProfileState,
    reducers: {
        SET_PROFILE: (state, action: PayloadAction<Trader>) => {
            state.availDDX = action.payload.availDDX;
            state.lockedDDX = action.payload.lockedDDX;
            state.payFeesInDDX = action.payload.payFeesInDDX;
            state.isDefault = action.payload.isDefault;
            state.epochId = action.payload.epochId;
            state.txOrdinal = action.payload.txOrdinal;
            if (action.payload.latestUpdate) {
                state.latestUpdate = action.payload.latestUpdate;
            }
            const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
            if (serializedOneClickTradingDataMap !== null) {
                const deserializedMap = new Map<string, OneClickTradingStorageData>(
                    Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
                );
                const data = deserializedMap.get(action.payload.trader);
                if (data) {
                    state.oneClickTradingEnabled = data.enabled;
                    state.oneClickTradingData = data;
                }
            }
            const optOutFlag = window.localStorage.getItem('disableOneClickTradingPrompt');
            if (optOutFlag) {
                state.disableOneClickTradingPrompt = optOutFlag === 'true' ? true : false;
            }
        },
        SET_IS_PAY_FEES_IN_DDX_LOADING: (state, action: PayloadAction<boolean>) => {
            state.isPayFeesInDDXLoading = action.payload;
        },
        SET_ESTIMATED_REWARDS: (state, action: PayloadAction<BigNumber>) => {
            state.estimatedRewards = action.payload;
        },
        UPDATE_ENROLLMENT_STATUS: (state, action: PayloadAction<EnrollmentStatus>) => {
            state.enrollmentStatus = action.payload;
        },
        UPDATE_ONE_CLICK_TRADING_STATUS: (state, action: PayloadAction<{ data: OneClickTradingStorageData }>) => {
            const data = action.payload.data;
            const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
            let deserializedMap: Map<string, OneClickTradingStorageData> | undefined = undefined;
            if (serializedOneClickTradingDataMap !== null) {
                deserializedMap = new Map<string, OneClickTradingStorageData>(
                    Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
                );
            } else {
                deserializedMap = new Map<string, OneClickTradingStorageData>();
            }
            deserializedMap.set(data.trader, {
                trader: data.trader,
                enabled: data.enabled,
                signature: data.signature,
                sessionPrivateKey: data.sessionPrivateKey,
            } as OneClickTradingStorageData);
            window.localStorage.setItem(
                ONE_CLICK_TRADING_DATA,
                JSON.stringify(Object.fromEntries(deserializedMap.entries())),
            );
            state.oneClickTradingEnabled = data.enabled;
            state.oneClickTradingData = data;
        },
        SET_DISABLE_ONE_CLICK_TRADING_PROMPT: (state, action: PayloadAction<boolean>) => {
            state.disableOneClickTradingPrompt = action.payload;
        },
    },
});

export const {
    SET_PROFILE,
    SET_ESTIMATED_REWARDS,
    SET_IS_PAY_FEES_IN_DDX_LOADING,
    UPDATE_ENROLLMENT_STATUS,
    UPDATE_ONE_CLICK_TRADING_STATUS,
    SET_DISABLE_ONE_CLICK_TRADING_PROMPT,
} = profileSlice.actions;

export const UPDATE_PROFILE_INTENT = createAsyncAction(
    ActionType.PROFILE_UPDATE_REQUEST,
    ActionType.PROFILE_UPDATE_SUCCESS,
    ActionType.PROFILE_UPDATE_FAILURE,
)<UpdateProfileIntent, RequestSequenced, RequestFailure>();

export const WITHDRAW_TRADER_INTENT = createAsyncAction(
    ActionType.WITHDRAW_DDX_REQUEST,
    ActionType.WITHDRAW_DDX_SUCCESS,
    ActionType.WITHDRAW_DDX_FAILURE,
)<WithdrawDDXIntent, RequestSequenced, RequestFailure>();

export const UPDATE_ONE_CLICK_TRADING_INTENT = createAsyncAction(
    ActionType.ONE_CLICK_TRADING_UPDATE_REQUEST,
    ActionType.ONE_CLICK_TRADING_UPDATE_SUCCESS,
    ActionType.ONE_CLICK_TRADING_UPDATE_FAILURE,
)<UpdateOneClickTradingIntent, RequestSequenced, RequestFailure>();
