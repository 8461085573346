import { Market, Token, TokenMetaData } from '@derivadex/types';
import { BigNumber } from 'bignumber.js';

import { UI_DECIMALS_DISPLAYED_DEFAULT_PRECISION } from './constants';

export const tokenAmountInUnitsToBigNumber = (amount: BigNumber, decimals: number): BigNumber => {
    const decimalsPerToken = new BigNumber(10).pow(decimals);
    return amount.div(decimalsPerToken);
};

export const tokenAmountInUnits = (amount: BigNumber, decimals: number, toFixedDecimals = 2): string => {
    return tokenAmountInUnitsToBigNumber(amount, decimals).toFixed(toFixedDecimals);
};

export const unitsInTokenAmount = (units: string, decimals: number): BigNumber => {
    const decimalsPerToken = new BigNumber(10).pow(decimals);

    return new BigNumber(units).multipliedBy(decimalsPerToken);
};

export const tokenSymbolToDisplayString = (symbol: string): string => {
    return symbol.toString().toUpperCase();
};

// After old frontend removal, refactor this types to Token and this cast is no longer needed
export const mapTokensMetadataToTokens = (tokens: TokenMetaData[]): Token[] => {
    return tokens as Token[];
};

export const getTokenForSymbol = (symbol: string, tokens: Token[]): Token => {
    const token = tokens.find((it) => it.symbol.toLowerCase() === symbol);
    if (token === undefined) {
        throw new Error(`Could not find token data for symbol ${symbol}`);
    }
    return token;
};
